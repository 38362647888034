import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import Page from '../../../components/page.vue'
import NoData from '../../../components/NoData.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'GeneralJournaDetail',
	data(){
		return{
			JournalModelInfo:'',//详情
			JournalList:'',//获取精选期刊
			pageIndex:1,
			pageSize:12,
			pageCount:0,
			searchWord:''
		}
	},
	methods:{
		GetJournalModel(){ //获取期刊详情
			var that = this
			CheckInService.GetJournalModel(that.$route.query.id,that.$UserID).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					if(res.Result.EntryModel.ReleaseState == 1 ){
						that.JournalModelInfo = res.Result
					}else{
						that.$alert('该资源未发布', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								if (action == "confirm") {
									window.close()
								}
								window.close()
							}
						});
					}
					
				}
			})
		},
		GetJournalList(){ //获取精选期刊
			var that = this
			CheckInService.GetJournalList(that.pageIndex,that.pageSize,that.$route.query.id,that.searchWord.trim()).then(res=>{
				if(res.Tag == 1){
					that.JournalList = res.Result
					that.pageCount = res.TotalCount
				}
			})
		},
		pageChange(page){ //分页
			this.pageIndex = page
			this.GetJournalList()
			window.scrollTo(0,0)
		},
		toSearch(){
			this.pageIndex = 1
			this.pageCount = 0
			this.GetJournalList()
		},
		toElementDetail(value){ //词条详情
			if(value.type == 1 || value.id){
				window.open(this.$WebUrl + 'ElementDetail?id=' + value.id)
			}
		},
	},
	created(){
		this.GetJournalModel()
		this.GetJournalList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'Page':Page,
		'NoData':NoData
	}
}